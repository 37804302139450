import React, { Component } from "react";
import { connect } from "react-redux";

import Aux1 from "../Aux1/Aux1";
import Login from "../../components/Launch/Login";

const urlArray = ["/", "/home", "/events", "/onelogin"];

class Layout extends Component {
  state = {
    showSideDrawer: false,
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Aux1>{this.props.children}</Aux1>;
    } else if (
      urlArray.find(
        (x) => x === this.props.children._owner.pendingProps.location.pathname
      ) === undefined &&
      (localStorage.token === undefined || localStorage.token === "")
    ) {
      localStorage.setItem(
        "templink",
        this.props.children._owner.pendingProps.location.pathname.replace(
          "#",
          ""
        )
      );
      return (
        <Aux1>
          <Login></Login>
        </Aux1>
      );
    } else {
      return <Aux1>{this.props.children}</Aux1>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(Layout);
